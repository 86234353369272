import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { CommonService } from '../../shared/common.service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class FrontLayoutService {
    environment: any;

    constructor(private commonService: CommonService, private http: HttpClient) { }

    login(data: any): Observable<any> {
        return this.http.post(this.commonService.rootData.rootUrl + 'auth/login', data);
    }

    register(data: any): Observable<any> {
        return this.http.post(this.commonService.rootData.rootUrl + 'auth/register', data);
    }


    forgotPassword(data: any): Observable<any> {
        return this.http.post(this.commonService.rootData.rootUrl + 'auth/forgotpassword-user', data);
    }
    otpVerification(data: any): Observable<any> {
        return this.http.post(this.commonService.rootData.rootUrl + 'auth/otp-verification-User', data);
    }
    resendOtp(data: any): Observable<any> {
        return this.http.post(this.commonService.rootData.rootUrl + 'auth/resend-otp-User', data);
    }
    resetPassword(data: any): Observable<any> {
        return this.http.post(this.commonService.rootData.rootUrl + 'auth/reset-password-User', data);
    }

    getDemonstrationListByType(data: any): Observable<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myUserToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'demonstration/get-demonstrationListByType', data, { headers: headers });
    }

    getDemonstrationDetailsByIndex(data: any): Observable<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myUserToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'demonstration/get-demonstrationDetailsByIndex', data, { headers: headers });
    }

    saveBusinessInquiry(saveContactMasterData: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myUserToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'businessInquiryForm/create', saveContactMasterData, { headers: headers });
    }

    getPlanMaster() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myUserToken')}`
        })
        return this.http.get(this.commonService.rootData.rootUrl + 'planCreation/get-planCreationDataList', { headers: headers });
    }

    newsLatterSubscribe(params) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myUserToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'newsLetterSubscribe/newsLetterSubscribe', params, { headers: headers });
    }

    payPalProductList() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: this.commonService.payPal.authToKen
        })
        let url = environment.paypalEndpoint + "/v1/catalogs/products";
        return this.http.get(url, { headers: headers });
    }

    subscribeCreate(params: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myUserToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'subscription/subscriptionCreate', params, { headers: headers });
    }

    cancelSubscription(params) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myUserToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'subscription/cancelled', params, { headers: headers });
    }

    getHomeDemo() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myUserToken')}`
        })
        return this.http.get(this.commonService.rootData.rootUrl + 'demonstration/get-activeDemonstrationList', { headers: headers });
    }

    getDemoMasterId(params: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myUserToken')}`
        })
        return this.http.get(this.commonService.rootData.rootUrl + 'demonstration/get-demonstratioListById', { params: params, headers: headers });
    }
}