import { Component, OnInit } from '@angular/core';
import { CommonService } from './shared/common.service';
import { AdminLayoutService } from '../app/layouts/admin-layout/admin-layout.service';
import { HttpClient } from '@angular/common/http'
import { NgxSpinnerService } from "ngx-spinner";
import { StorageService, StorageKey } from '../app/shared/storage.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    colour;
    constructor(private http: HttpClient, private commonService: CommonService, private spinner: NgxSpinnerService, public adminLayoutService: AdminLayoutService, public storageService: StorageService) {
        
        setTimeout(() => {
            if (this.storageService.getValue('colour') === '1') {
                this.colour = '#9c27b0'
            } else if (this.storageService.getValue('colour') === '2') {
                this.colour = '#00bcd4'
            } else if (this.storageService.getValue('colour') === '3') {
                this.colour = '#4caf50'
            } else if (this.storageService.getValue('colour') === '4') {
                this.colour = '#ff9800'
            } else if (this.storageService.getValue('colour') === '5') {
                this.colour = '#f44336'
            } else if (this.storageService.getValue('colour') === '6') {
                this.colour = '#e91e63'
            } else {
                this.colour = '#e91e63'
            }

        }, 0);
    }
    ngOnInit() {
        if (location.hostname!="localhost" && location.protocol !== 'https:') {
            location.replace(`https:${location.href.substring(location.protocol.length)}`);
        }
    }
    
}
