import { Component, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService, StorageKey } from '../../shared/storage.service';
import { CookieService } from 'ngx-cookie-service';
import { AdminLayoutService } from '../../layouts/admin-layout/admin-layout.service';
import { FrontLayoutService } from '../../layouts/front-layout/front-layout.service';
import { CommonService } from '../../shared/common.service';
import { CoreHelperService } from '../../Providers/core-helper/core-helper.service';
import * as jQuery from 'jquery';

declare const $: any;

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
    toggle = false;
    planDetails;
    profileName: any;
    message: string;
    fullName: string;
    isLogedIn;
    scrollDataHeaderClass: boolean = true;
    userId: any;
    subscriptionDetails: any = [];

    constructor(private element: ElementRef, private router: Router, public storageService: StorageService, private coreHelper: CoreHelperService, private cookieService: CookieService, public commonService: CommonService, public adminLayoutService: AdminLayoutService, public frontLayoutService: FrontLayoutService) {

        if (localStorage.getItem('acceptCookies') !== 'yes') {
            $("#acceptCookiesModal").modal({
                backdrop: "static",
                keyboard: false,
                show: true,
            });
        }
        else {
            $("#acceptCookiesModal").modal('hide');
        }

        this.userId = this.storageService.getValue(StorageKey.userId);
    }
    @HostListener('window:scroll', ['$event'])

    onWindowScroll() {
        let element = document.querySelector('#nav') as HTMLElement;
        if (window.pageYOffset > element.clientHeight) {
            element.classList.add('affix');
            element.getElementsByTagName("nav")[0].style.paddingTop = "10px";
        } else {
            element.classList.add('affix-top');
            element.classList.remove('affix');
            element.getElementsByTagName("nav")[0].style.paddingTop = "0";
        }
    }

    ngOnInit() {
        var t = jQuery,
            e = t("body");
        t("#main-menu").on("click", ".open-sub", function (e) {
            e.preventDefault();
            var i = t(this).closest("li, .box");
            if (t(i).hasClass("active")) t(i).children().last().slideUp(150), t(i).removeClass("active");
            else {
                var n = t(this).closest("li, .box").parent("ul, .sub-list").children("li, .box");
                t(n).is(".active") && t(n).removeClass("active").children("ul").slideUp(150), t(i).children().last().slideDown(150), t(i).addClass("active");
            }
        }),
            t("#main-menu")
                .find(".parent > a")
                .click(function (i) {
                    if (e.width() > 979 && navigator.userAgent.match(/iPad|iPhone|Android/i)) {
                        var n = t(this);
                        n.parent().hasClass("open") ? (n.parent().removeClass("open"), i.preventDefault()) : (i.preventDefault(), n.parent().addClass("open"));
                    }
                });

        this.onWindowScroll();
        if (this.storageService.getValue('userId')) {
            this.getUserDetails();
        }
        this.getSubscriptionDetails();
        setTimeout(() => {
            this.profileName = this.storageService.getValue('userName')
            if (this.storageService.getValue('myUserToken')) {
                this.isLogedIn = true;
            } else {
                this.isLogedIn = false;
            }
        }, 0);
    }

    getUserDetails() {
        let AccountObj = {
            _id: this.storageService.getValue('userId')
        }

        this.adminLayoutService.getAccountDetailsByID(AccountObj).subscribe((Response: any) => {
            if (Response.meta.code == 200) {
                if (Response.data.status == 2) {
                    this.logout();
                }
            }
        })
    }

    logout() {
        this.storageService.removeValue(StorageKey.myUserToken);
        this.storageService.removeValue(StorageKey.userId);
        this.storageService.removeValue(StorageKey.userName);
        this.storageService.removeValue(StorageKey.email);
        this.storageService.removeValue(StorageKey.tqllcData);
        this.storageService.removeValue('goToPlanPage');
        window.location.reload();
    }

    subscriptionModal() {
        this.getSubscriptionDetails();
        $("#subscriptionPopup").modal({ backdrop: 'static', keyboard: false, show: true });
    }

    upgradeSubscription(subscriptionID, PlanId) {
        alert(subscriptionID);
        alert(PlanId);
    }

    // cancelSubscription(subscriptionID) {
    //     debugger
    //     let params = {
    //         subscriptionID: subscriptionID,
    //         reason: 'cancel subscription'
    //     }
    //     this.frontLayoutService.cancelSubscription(params).subscribe((Response: any) => {
    //         if (Response.meta.code == 200) {
    //             this.getSubscriptionDetails();
    //             this.commonService.notifier.notify('success', 'Subscription cancel successfully...');
    //             window.location.reload();
    //         } else {
    //             this.commonService.notifier.notify('error', 'Somthing went wrong....');
    //         }
    //     });
    // }

    // activateSubscription(subscriptionID) {
    //     let params = {
    //         subscriptionID: subscriptionID,
    //         reason: 'activate subscription'
    //     }
    //     this.adminLayoutService.activateSubscriptionData(params).subscribe((Response: any) => {
    //         if (Response.meta.code == 200) {
    //             this.getSubscriptionDetails();
    //             this.commonService.notifier.notify('success', 'Subscription activate successfully...');
    //         } else {
    //             this.commonService.notifier.notify('error', 'Somthing went wrong....');
    //         }
    //     });
    // }

    scrollToElement($element) {
        const currentUrl = this.router.url
        if (currentUrl != "/home") {
            this.storageService.setValue(StorageKey.sectionId, $element);
            window.location.href = "/home";
        } else {
            $('.footer-item').removeClass('gray-bg');
            document.getElementById($element).scrollIntoView({ behavior: 'smooth', block: 'start', inline: "start" });
        }
    }

    getPlan(plan_id) {
        this.adminLayoutService.paypalPlanDetail(plan_id).subscribe((Response: any) => {
            this.planDetails = Response;
        });
    }

    getSubscriptionDetails() {
        let params = { userId: this.storageService.getValue('userId') };
        this.adminLayoutService.getUserSubscription(params).subscribe((Response: any) => {
            if (Response.meta.code == 200) {
                this.subscriptionDetails = Response.data.at(-1)
                this.getPlan(this.subscriptionDetails.planId);
            } else {
                this.subscriptionDetails = '';
            }
        });
    }
}
