import { Component, OnInit } from '@angular/core';
import { CommonService } from './../../shared/common.service';
import { StorageService, StorageKey } from '../../shared/storage.service';
import { AdminLayoutService } from '../../layouts/admin-layout/admin-layout.service';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    children: any;
}
declare interface TitlerouteInfo {
    path: string;
    title: string;
}
export const TITLEROUTES: TitlerouteInfo[] = [
    { path: '/admin/blog/blog-list', title: 'Blog List' },
    { path: '/admin/blog/add-new-blog', title: 'Add New Blog' },
    { path: '/admin/news/news-list', title: 'News List' },
    { path: '/admin/news/add-new-news', title: 'Add New News' },
    { path: '/admin/content', title: 'Content Management' },
    { path: '/admin/contact-list', title: 'Contact List' },
    { path: '/admin/demonstrations', title: 'Demonstration List' },
    { path: '/admin/user-list', title: 'User List' },
    { path: '/admin/plan-list', title: 'Plan List' },
    { path: '/admin/plan/add-new-plan', title: 'Add New Plan' },
    { path: '/admin/newsletter-list', title: 'Newsletter List' },
];

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
    [x: string]: any;
    menuItems: any[];
    id: any;
    toggle = {};

    constructor(public commonService: CommonService, public storageService: StorageService, public adminLayoutService: AdminLayoutService) {

        if (localStorage.getItem('acceptCookies') !== 'yes') {
            $("#acceptCookiesModal").modal({
                backdrop: "static",
                keyboard: false,
                show: true,
            });
        }
        else {
            $("#acceptCookiesModal").modal('hide');
        }

        setTimeout(() => {
            this.logo = this.commonService.rootData.uploadsUrl + "photos/" + this.storageService.getValue('logo')
        }, 0);
    }

    ngOnInit() {
        this.dropdownshow = false;
    }

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };

    dropdown() {
        this.dropdownshow = false;
        this.dropdownshow = true;
    }
    dropdownclose() {
        this.dropdownshow = false;
    }
}
