import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { StorageService, StorageKey } from '../../shared/storage.service';
import { CommonService } from '../../shared/common.service';
declare const $: any;

@Component({
    selector: 'app-account-layout',
    templateUrl: './account-layout.component.html',
    styleUrls: ['./account-layout.component.scss']
})
export class AccountLayoutComponent implements OnInit {
    constructor() {
        if (localStorage.getItem('acceptCookies') !== 'yes') {
            $("#acceptCookiesModal").modal({
                backdrop: "static",
                keyboard: false,
                show: true,
            });
        }
        else {
            $("#acceptCookiesModal").modal('hide');
        }
    }

    ngOnInit() {

    }

}
