import { Component, OnInit } from '@angular/core';
import { AdminLayoutService } from '../../layouts/admin-layout/admin-layout.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { FrontLayoutService } from '../../layouts/front-layout/front-layout.service';
import { CommonService } from '../../shared/common.service';
import { StorageService, StorageKey } from '../../shared/storage.service';
import { Router } from '@angular/router';

declare const $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  test: Date = new Date();
  isLogedIn;
  subscribeContentData = false;
  activeContentListData: any = [];

  constructor(private fb: FormBuilder, private router: Router, public frontLayoutService: FrontLayoutService, public commonService: CommonService, public storageService: StorageService, public adminLayoutService: AdminLayoutService) {
    if (localStorage.getItem('acceptCookies') !== 'yes') {
      $("#acceptCookiesModal").modal({
        backdrop: "static",
        keyboard: false,
        show: true,
      });
    }
    else {
      $("#acceptCookiesModal").modal('hide');
    }
  }

  ngOnInit() {
    if (this.storageService.getValue('myUserToken')) {
      this.isLogedIn = true;
    } else {
      this.isLogedIn = false;
    }
    this.activeContentList();
  }

  activeContentList() {
    const currentUrl = this.router.url
    if (currentUrl == "/home") {
      $('.footer-item').removeClass('gray-bg');
    } else {
      $('.footer-item').addClass('gray-bg');
    }
    this.adminLayoutService.getActiveContentList().subscribe((Response: any) => {
      if (Response.meta.code == 200) {
        this.activeContentListData = Response.data;
      }
    })
  }

  newsLetterSubscribeForm = this.fb.group({
    email: ['', Validators.required],
  })

  newsLetterSubscribe() {
    if (this.newsLetterSubscribeForm.invalid) {
      this.subscribeContentData = true;
      return;
    }
    let params = {
      email: this.newsLetterSubscribeForm.value.email
    }
    this.frontLayoutService.newsLatterSubscribe(params).subscribe((Response: any) => {
      if (Response.meta.code == 200) {
        this.newsLetterSubscribeForm.reset();
        this.subscribeContentData = false;
        this.commonService.notifier.notify('success', 'Thank You For Subscribe Our NewsLetter Service.');
      }
      else {
        this.commonService.notifier.notify('error', Response.meta.message);
      }
    }, (error) => {
      console.log(error);
    });
  }
  scrollToElement($element) {
    const currentUrl = this.router.url
    if (currentUrl != "/home") {
      this.storageService.setValue(StorageKey.sectionId, $element);
      window.location.href = "/home";
    } else {
      document.getElementById($element).scrollIntoView({ behavior: 'smooth', block: 'start', inline: "start" });
    }
  }

}
