export const environment = {
    production: false,
    // WebAPIUrl: 'http://localhost:3005/v1/',
    // uploadsUrl: 'http://localhost:3005/uploads/',
    // frontendUrl: 'http://localhost:4200/',
    // backendUrl: 'http://localhost:4200/admin/',
    // sanbox    
    // paypalClientId: 'AcUzMRg2RcQs4jGZZgqiD0FiWgWFEm3Rf4XwB95wPOJ0lTPT6yXXS6FqgCF19OEp2USPHzwId6Ntov9W',
    // paypalSecret: 'EDGqiO4-dsDB7y020w1qI5khSHlcI1PdKqgqflNsPqCRKwIU23HILIk1Ojq_c65Kp-yS6MEQ54fy1tKg',
    // paypalEndpoint:'https://api-m.sandbox.paypal.com'

    // live
    // paypalClientId: 'ATHhyJFOdatlxOX5IUKIiPSuHwd0UiewceWzE2ONoMLfq0uYfIAPL-uT4kNzD560OnwvrdljksAceCYA',
    // paypalSecret: 'EFy_iVU00EYjmRdqJInEuYPxWlC_gfgA2Pqyte3zue3QJ5Ba9wZ5Nx1RRu1RZlmvVfkI9ff6wZlbfpL5',
    // paypalEndpoint:'https://api-m.paypal.com'

    //live
    frontendUrl: 'https://tqllc.company/',
    backendUrl: 'https://tqllc.company/admin/',
    WebAPIUrl: 'https://api.tqllc.company/v1/',
    uploadsUrl: 'https://api.tqllc.company/uploads/',
    paypalClientId: 'ATHhyJFOdatlxOX5IUKIiPSuHwd0UiewceWzE2ONoMLfq0uYfIAPL-uT4kNzD560OnwvrdljksAceCYA',
    paypalSecret: 'EFy_iVU00EYjmRdqJInEuYPxWlC_gfgA2Pqyte3zue3QJ5Ba9wZ5Nx1RRu1RZlmvVfkI9ff6wZlbfpL5',
    paypalEndpoint:'https://api-m.paypal.com'
};