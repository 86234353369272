import { NgModule } from '@angular/core';
// import { CommonModule, } from '@angular/common';
// import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { FrontLayoutComponent } from './layouts/front-layout/front-layout.component';
import { AccountLayoutComponent } from './layouts/account-layout/account-layout.component';
import { ComingSoonComponent } from './layouts/coming-soon/coming-soon.component';


const routerOptions: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64],
};
var today = new Date();
var todayDate = (today.getMonth() + 1) + '-' + today.getDate() + '-' + today.getFullYear();
var tdate = new Date(todayDate);
var launhingDay = new Date("4 July, 2022");
var launhingDate = (launhingDay.getMonth() + 1) + '-' + launhingDay.getDate() + '-' + launhingDay.getFullYear()
var ldate = new Date(launhingDate);
console.log(tdate, '111111111', ldate, tdate <= ldate)
const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
    },
    {
        path: '',
        component: FrontLayoutComponent,
        children: [{
            path: '',
            loadChildren: './layouts/front-layout/front-layout.module#FrontLayoutModule'
        }]
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [{
            path: '',
            loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
        }]
    }
    , {
        path: '',
        component: LoginLayoutComponent,
        children: [{
            path: '',
            loadChildren: './layouts/login-layout/login-layout.module#LoginLayoutModule'
        }]
    }
    , {
        path: '',
        component: AccountLayoutComponent,
        children: [{
            path: '',
            loadChildren: './layouts/account-layout/account-layout.module#AccountLayoutModule'
        }]
     }
    //,
    // {
    //     path: '',
    //     component: ComingSoonComponent,
    //     children: [{
    //         path: 'coming-soon',
    //         loadChildren: './layouts/coming-soon/coming-soon.module#ComingSoonModule'
    //     }]
    // }

];

// @NgModule({
//     imports: [
//         CommonModule,
//         BrowserModule,
//         RouterModule.forRoot(routes, routerOptions)
//     ],
//     exports: [
//     ],
// })
@NgModule({

  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled', scrollPositionRestoration: 'top' })],

  exports: [RouterModule]

})
export class AppRoutingModule { }
