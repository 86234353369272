import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { CommonService } from '../../shared/common.service';
import { environment } from '../../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class AdminLayoutService {
    environment: any;

    constructor(private commonService: CommonService, private http: HttpClient) { }

    SaveuserMaster(createuserMasterData: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'adminAuth/admin-create', createuserMasterData, { headers: headers });
    }

    changePassword(updatechangepwdData: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'adminAuth/changePassword-Admin', updatechangepwdData, { headers: headers });
    }

    // active content list
    getActiveContentList() {
        let headers = new HttpHeaders({
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.get(this.commonService.rootData.rootUrl + 'content/get-activeContentDataList', { headers: headers });
    }

    //blog
    SaveblogMaster(createblogMasterData: any, isEditFlow: boolean =false) {
        let headers = new HttpHeaders({
            /*'Content-Type': 'form-data',*/
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        if(isEditFlow) {
            return this.http.put(this.commonService.rootData.rootUrl + 'blog/update-relation-master-by-id', createblogMasterData, { headers: headers });
        }else{
            return this.http.post(this.commonService.rootData.rootUrl + 'blog/Blog-create', createblogMasterData, { headers: headers });
        }
    }
    //blog-details
    SaveblogDetails(createblogMasterData: any, isEditFlow: boolean = false) {
        let headers = new HttpHeaders({
            /*'Content-Type': 'form-data',*/
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        if(isEditFlow){
            return this.http.put(this.commonService.rootData.rootUrl + 'blog/update-relation-details-by-id', createblogMasterData, { headers: headers });
        }else{
            return this.http.post(this.commonService.rootData.rootUrl + 'blog/Blog-details-create', createblogMasterData, { headers: headers });
        }
    }

    getblogMaster() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.get(this.commonService.rootData.rootUrl + 'blog/blog-List', { headers: headers });
    }

    getActiveBlogMaster() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.get(this.commonService.rootData.rootUrl + 'blog/active-Blog-List', { headers: headers });
    }

    getLatestBlog() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.get(this.commonService.rootData.rootUrl + 'blog/blogListForHomeScreen', { headers: headers });
    }

    Statusblog(updatestatusblogData: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'blog/blog-statusActiveDeactive', updatestatusblogData, { headers: headers });
    }

    getblogMasterId(params: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.get(this.commonService.rootData.rootUrl + 'blog/blog-DeatilsById', { params: params, headers: headers });
    }

    getPublicRelationshipById(id: string){
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.get(this.commonService.rootData.rootUrl + 'blog/public-relationship-by-id/'+id, { headers: headers });
    }

    UpdateblogMaster(updateblogMasterData: any) {
        let headers = new HttpHeaders({
            //'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'blog/blog-update', updateblogMasterData, { headers: headers });
    }

    // content
    statusContent(updateStatusContentData: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'content/get-contentActiveDeActive', updateStatusContentData, { headers: headers });
    }
    getContentMaster() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.get(this.commonService.rootData.rootUrl + 'content/get-contentDataList', { headers: headers });
    }

    getContentActiveMaster() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.get(this.commonService.rootData.rootUrl + 'content/get-activeContentDataList', { headers: headers });
    }

    getContentMasterId(params: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.get(this.commonService.rootData.rootUrl + 'content/get-contentListById', { params: params, headers: headers });
    }
    getContentBySlug(params: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.get(this.commonService.rootData.rootUrl + 'content/get-contentListBySlug', { params: params, headers: headers });
    }
    updateContentMaster(updateContentMasterData: any) {
        let headers = new HttpHeaders({
            //'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'content/get-contentUpdate', updateContentMasterData, { headers: headers });
    }

    //news
    SavenewsMaster(createnewsMasterData: any) {
        let headers = new HttpHeaders({
            /*'Content-Type': 'form-data',*/
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'news/News-create', createnewsMasterData, { headers: headers });
    }

    Statusnews(updatestatusnewsData: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'news/newsStatus-active-deactive', updatestatusnewsData, { headers: headers });
    }
    getnewsMaster() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.get(this.commonService.rootData.rootUrl + 'news/get-newsList', { headers: headers });
    }

    getActiveNewsMaster() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.get(this.commonService.rootData.rootUrl + 'news/get-newsActiveList', { headers: headers });
    }

    getnewsMasterId(params: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.get(this.commonService.rootData.rootUrl + 'news/get-newsDetailsList', { params: params, headers: headers });
    }
    UpdatenewsMaster(updatenewsMasterData: any) {
        let headers = new HttpHeaders({
            //'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'news/news-update', updatenewsMasterData, { headers: headers });
    }

    //contact

    saveContactMaster(saveContactMasterData: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'contact/create', saveContactMasterData, { headers: headers });
    }

    getContactMaster() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.get(this.commonService.rootData.rootUrl + 'contact/get-contantDataList', { headers: headers });
    }

    //demonstration 

    getDemonstrationTypeList() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.get(this.commonService.rootData.rootUrl + 'demonstration/get-typeMasterList', { headers: headers });
    }

    saveDemonstration(saveDemonstrationMasterData: any) {
        let headers = new HttpHeaders({
            /*'Content-Type': 'form-data',*/
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'demonstration/demonstrationSave', saveDemonstrationMasterData, { headers: headers });
    }

    getDemonstrationMaster() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.get(this.commonService.rootData.rootUrl + 'demonstration/get-demonstrationList', { headers: headers });
    }

    statusDemonstration(updateStatusDemonstrationData: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'demonstration/get-demonstrationActiveDeActive', updateStatusDemonstrationData, { headers: headers });
    }

    getDemoMasterId(params: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.get(this.commonService.rootData.rootUrl + 'demonstration/get-demonstratioListById', { params: params, headers: headers });
    }

    updateDemoMaster(updateDemoMasterData: any) {
        let headers = new HttpHeaders({
            //'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'demonstration/DemonstrationUpdate', updateDemoMasterData, { headers: headers });
    }

    // qus and ans
    saveFaqMaster(saveContactMasterData: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'demonstration/questionContentSave', saveContactMasterData, { headers: headers });
    }

    getFaqMasterId(params: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'demonstration/get-questionListById', params, { headers: headers });
    }

    updateFaqMaster(params: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'demonstration/questionContentUpdate', params, { headers: headers });
    }

    deleteFaq(params: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'demonstration/deleteQuestionList', params, { headers: headers });
    }

    // user API
    getuserMaster() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.get(this.commonService.rootData.rootUrl + 'auth/get-userList', { headers: headers });
    }

    Statususer(updatestatususerData: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'auth/userActiveDeActive', updatestatususerData, { headers: headers });
    }

    //

    getAccountDetailsByID(params: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.get(this.commonService.rootData.rootUrl + 'auth/userDetails-ById', { params: params, headers: headers });
    }

    // plan

    statusPlan(updateStatusPlanData: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'planCreation/Plan-active-deactive', updateStatusPlanData, { headers: headers });
    }

    getPlanMaster() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.get(this.commonService.rootData.rootUrl + 'planCreation/get-planCreationDataList', { headers: headers });
    }

    // paypalPlanList() {
    //     let headers = new HttpHeaders({
    //         'Content-Type': 'application/json',
    //         Authorization: this.commonService.payPal.authToken
    //     let url = environment.paypalEndpoint + "/v1/billing/plans";
    //     return this.http.get(url, { headers: headers });
    // }

    paypalPlanDetail(plan_id) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: this.commonService.payPal.authToKen
        })
        let url = environment.paypalEndpoint+"/v1/billing/plans/"+plan_id;
        return this.http.get(url, { headers: headers });
    }

    savePlanMaster(savePlanMasterData: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'planCreation/create', savePlanMasterData, { headers: headers });
    }

    getPlanMasterId(params: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.get(this.commonService.rootData.rootUrl + 'planCreation/get-planCreatioListById', { params: params, headers: headers });
    }

    updatePlanMaster(params: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'planCreation/planCreatioUpdate', params, { headers: headers });
    }

    payPalProductList() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: this.commonService.payPal.authToKen
        });
        let url = environment.paypalEndpoint + "/v1/catalogs/products";
        return this.http.get(url, { headers: headers });
    }
    
    // newsletter
    getNewsletterMaster() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.get(this.commonService.rootData.rootUrl + 'newsLetterSubscribe/newsLetterSubscribeList', { headers: headers });
    }

    //subscription
    getUserSubscription(params) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'subscription/get-subscriptionDataList', params, { headers: headers });
    }

    activateSubscriptionData(params) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
        })
        return this.http.post(this.commonService.rootData.rootUrl + 'subscription/active', params, { headers: headers });
    }

    changeSubscription(params: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: this.commonService.payPal.authToKen
        });
        let url = environment.paypalEndpoint + "/v1/billing/subscriptions/" +params.subscriptionID +'/revise'; 
        return this.http.post(url, {plan_id: params.planId}, { headers: headers });
    }

    
    reSendOtp(params) {
        return this.http.post(this.commonService.rootData.rootUrl + 'auth/resend-otp-User', params);
    }

    permanentDelete(userId){
        if (!userId || !userId.length) {
            console.log("No User Id found to delete")
            return null
        }
        let url = this.commonService.rootData.rootUrl + 'adminAuth/delete/'+userId
        return this.http.delete(url, { 
            headers : {
                'Authorization': `Bearer ${localStorage.getItem('myAdminToken')}`
            }
        })
    }
    
}