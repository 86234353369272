import { Component, OnInit } from "@angular/core";
declare const $: any;

@Component({
  selector: "app-accept-cookies",
  templateUrl: "./accept-cookies.component.html",
  styleUrls: ["./accept-cookies.component.css"],
})
export class AcceptCookiesComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
    if (localStorage.getItem('acceptCookies') !== 'yes') {
      $("#acceptCookiesModal").modal({
        backdrop: "static",
        keyboard: false,
        show: true,
      });
    }
    else {
      $("#acceptCookiesModal").modal('hide');
    }
  }

  acceptCookies() {
    localStorage.setItem('acceptCookies', 'yes');
    $("#acceptCookiesModal").modal('hide');
  }
}
