import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class StorageService {

    constructor() { }

    getValue(key: string): string {
        return localStorage.getItem(key);
    }

    setValue(key: string, value: string): void {
        localStorage.setItem(key, value);
    }

    removeValue(key: string): void {
        localStorage.removeItem(key);
    }

}


export class StorageKey {
    public static myUserToken = 'myUserToken';
    public static userId = 'userId';
    public static userName = 'userName';
    public static myAdminToken = 'myAdminToken';
    public static adminName = 'adminName';
    public static adminId = 'adminId';
    public static email = 'email';
    public static profileImage = 'profileImage';
    public static language = 'language';
    public static goToPlanPage = 'goToPlanPage';
    public static sectionId = 'sectionId';
    public static tqllcData = 'tqllcData';
}
